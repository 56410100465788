<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
              <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
            </div>
            <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
                <p class="mb-0">{{ $t("loading...") }}</p>
            </div>
            </div>
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <!-- <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack"> -->
                <h1 class="main_text text text_main_title">{{ $t("arbitrage") }}</h1>
                <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i>
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>

            <!-- <div class="etnex-purchase-container colorful_bg">
                <div class="purchase-content row">
                  <div class="col-6">
                    <div class="asset-info">
                    <p class="text-12 text mb-0">ETNEX - </p>
                    <p class="text-15 text font-weight-semibold mb-0">{{ $t("my_wallet") }}</p>
                    <p class="text-8 text mb-0">{{ $t("Your Gateway to Smart Profitability") }}</p>
                   
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="asset-info text-right">
                      <img src="../../../assets/images/etnex_home/Stock.png" alt="Info" class="info-icon">
                    </div>
                  </div>
                </div>
            </div> -->
            <div id="pairings">
              <h2 class="text_h2_etnex text text-light-green">{{ $t("market")}} {{ $t("pairings") }} </h2>
            </div>
            <div class="etnex-card black-dark-blue-card mt-3 mb-4">
              <div class="purchase-content">
                <div class="asset-info pt-2">              
                  <div class="price-list">
                    <div class="head mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo"></div>
                      </b-col>
                      <b-col cols="3" class="p-0">
                        <div class="logo ml-1" style="max-width: 80%;"><img src="../../../assets/images/etnex_home/binance.png" alt="" /></div>
                      </b-col>
                      <b-col cols="3" class="p-0">
                        <div class="logo ml-3" style="max-width: 45%;"><img src="../../../assets/images/etnex_home/bybit.png" alt="" /></div>
                      </b-col>
                      <b-col cols="3" class="p-0">
                        <div class="logo ml-2" style="max-width: 70%;"><img src="../../../assets/images/etnex_home/kucoin.png" alt="" /></div>
                      </b-col>
                    </div>
                    <div class="exchange mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo text-white text-12">
                          <img src="../../../assets/images/etnex_home/btc.png" alt="" style="max-width: 18%;"/> BTCUSDT
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ binance_btcprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ bybit_btcprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ kucoin_btcprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                    </div>
                    <div class="exchange mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo text-white text-12">
                          <img src="../../../assets/images/etnex_home/eth.png" alt="" style="max-width: 18%;"/> ETHUSDT
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ binance_ethprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ bybit_ethprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ kucoin_ethprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                    </div>
                    <div class="exchange mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo text-white text-12">
                          <img src="../../../assets/images/etnex_home/solana.png" class="rounded-circle" alt="" style="max-width: 18%;"/> SOLUSDT
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ binance_solprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ bybit_solprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ kucoin_solprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                    </div>
                    <div class="exchange mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo text-white text-12">
                          <img src="../../../assets/images/etnex_home/xrp.png"  class="rounded-circle" alt="" style="max-width: 18%;"/> XRPUSDT
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ binance_xrpprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ bybit_xrpprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ kucoin_xrpprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                    </div>
                    <div class="exchange mb-3">
                      <b-col cols="3" class="p-0">
                        <div class="logo text-white text-12">
                          <img src="../../../assets/images/etnex_home/ada.webp"  class="rounded-circle" alt="" style="max-width: 18%; background: white;"/> ADAUSDT
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ binance_adaprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ bybit_adaprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                      <b-col cols="3" class="p-0" >
                        <div class="crypto">
                          <span class="price text-white" >{{ kucoin_adaprice.substring(0,7) }}</span>                 
                        </div>
                      </b-col>
                    </div>
                  <!-- <div class="exchange mb-3" v-for="item in dataList" :key="item.id">
                    <b-col cols="3" class="p-0">
                      <div v-if="item.id === 'btc'" class="logo text-white text-12">
                        <img src="../../../assets/images/etnex_home/btc.png" alt="" style="max-width: 18%;"/> BTCUSDT
                      </div>
                      <div v-else-if="item.id === 'eth'" class="logo text-white text-12">
                        <img src="../../../assets/images/etnex_home/eth.png" alt="" style="max-width: 18%;"/> ETHUSDT
                      </div>
                      <div v-else-if="item.id === 'sol'" class="logo text-white text-12">
                        <img src="../../../assets/images/etnex_home/solana.png" class="rounded-circle" alt="" style="max-width: 18%;"/> SOLUSDT
                      </div>
                      <div v-else-if="item.id === 'xrp'" class="logo text-white text-12">
                        <img src="../../../assets/images/etnex_home/xrp.png"  class="rounded-circle" alt="" style="max-width: 18%;"/> XRPUSDT
                      </div>
                      <div v-else-if="item.id === 'ada'" class="logo text-white text-12">
                        <img src="../../../assets/images/etnex_home/ada.webp"  class="rounded-circle" alt="" style="max-width: 18%; background: white;"/> ADAUSDT
                      </div>
                    </b-col>
                    <b-col cols="3" class="p-0" >
                      <div class="crypto">
                        <span class="price text-white" >{{ item.binance.price.substring(0,7) }}</span>                 
                      </div>
                    </b-col>
                    <b-col cols="3" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ item.bybit.price.substring(0,7) }}</span>            
                      </div>
                    </b-col>
                    <b-col cols="3" class="p-0">
                      <div class="crypto">
                        <span class="price text-white">{{ item.kucoin.price.substring(0,7) }}</span>
                      </div>
                    </b-col>
                  </div> -->
                </div>
              </div>
          </div>
      </div>

            <h2 class="text_h2_etnex text text-light-green">{{ $t("package_type") }}</h2>
            <div class="etnex-package-container black-dark-blue-card mb-3" v-for="p_type in packagetype" :key="p_type.id">
                <div class="package-content">
                    <div class="purchase-details">
                        <div class="d-flex justify-content-between align-items-center pb-10 mt-2">
                            <h3 class="text text_16 weight_600">{{ $i18n.locale == "zh" ? p_type.name_cn : p_type.name }}</h3>
                            <h3 class="text text_16 weight_600">{{ $t("min") }} <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon" style="max-width: 14px;margin-right:2px;"/>{{ p_type.min_etnex }} ETNEX</h3>
                        </div>
                        <hr>
                        <div class="stat-item">
                            <div class="stat-title text">{{ $t("contract_period") }}</div>
                            <div class="stat-value text">{{ p_type.day }} Days</div>
                        </div>
                        <hr>
                        <div class="stat-item">
                            <div class="stat-title text">{{ $t("apr") }}</div>
                            <div class="stat-value text">{{ getExpectedApr(p_type) }}</div>
                        </div>
                        <hr>
                        <div class="stat-item">
                            <div class="stat-title text">{{ $t("daily_roi") }}</div>
                            <div class="stat-value text">{{ getDailyRoi(p_type) }}</div>
                            <!-- {{ calculateMaturityDate(p_type.day) }} -->
                        </div>
                        <hr>
                        <div class="purchase-statement-container">
                            <button class="make-purchase-button colorful_bg" @click="Keyinamount(p_type.min_etnex, p_type.id, p_type.name)">
                                {{ $t("start_trade") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RemindPopop v-if="showRemindPopop" @close="showRemindPopop = false" />
        <enter-pin ref="enterPin" @submit-pin="submitForm"></enter-pin>
        <EnterAmount v-if="showKeyinamount" :min-etnex="+min" :name="name" @confirm-amount="handleAmountConfirm" />
        <Dialog ref="msg"></Dialog>
        <Success v-if="SuccessModal" @closePopup="SuccessModal = false" :message="successMessage"></Success>
    </div>
  </template>
  <script>
  import EnterPin from '../pin/enter_pin.vue';
  import EnterAmount from '../popup/keyinamount.vue';
  import Dialog from "../../../components/dialog.vue";
  import Success from '../popup/profilesuccess.vue';
  import RemindPopop from '../popup/remind.vue';
  import { getPackageType, upgradeStaking, getStakingRecord, getBitcoinPrice, getDownloadLink } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  export default {
    components: {
      EnterPin,
      Dialog,
      Success,
      EnterAmount,
      RemindPopop,
    },
    data() {
      return {
        packagetype: [],
        pay_type: 'point2',
        SuccessModal: false,
        isLoading: false,
        showKeyinamount: false,
        enteredAmount: "",
        packageRecord: [],
        showRemindPopop: false,
        binance_btcprice: "",
        bybit_btcprice: "",
        kucoin_btcprice: "",
        binance_ethprice: "",
        bybit_ethprice: "",
        kucoin_ethprice: "",
        binance_adaprice: "",
        bybit_adaprice:"",
        kucoin_adaprice: "",
        binance_xrpprice: "",
        bybit_xrpprice: "",
        kucoin_xrpprice: "",
        binance_solprice: "",
        bybit_solprice:"",
        kucoin_solprice: "",
        dataList: [],
      };
    },
    mounted() {
      this.loadPackage();
      this.loadItems();
      this.priceUpdater = setInterval(() => {
      this.cryptoPrice();
      }, 5000);
      window.addEventListener("beforeunload", this.unload);
    },
    methods: {
      getDailyRoi(p_type) {
      if (p_type.id == "1") {
        return "0.18% ~ 0.36%";
      } 
      if(p_type.id == "2"){
        return "0.36% ~ 0.72%";
      }
      if(p_type.id == "3"){
        return "0.36% ~ 1%";
      }
      if(p_type.id == "4"){
        return "0.36% ~ 1.39%";
      }
      },
      getExpectedApr(p_type) {
        if (p_type.id == "1") {
          return "65.16% ~ 131.4%";
        } 
        if (p_type.id == "2"){
          return "131.4% ~ 262.8%";
        }
        if (p_type.id == "3"){
          return "131.4% ~ 365%";
        }
        if (p_type.id == "4"){
          return "131.4% ~ 507.35%";
        }
      },
      Keyinamount(min, id, name) {
        this.min = min;
        this.name = name
        this.p_id = id;
        this.showKeyinamount = !this.showKeyinamount;
      },
      handleAmountConfirm(amount) {
        // alert(amount);
        this.enteredAmount = amount;
        this.showKeyinamount = false;
        this.$refs.enterPin.open();
      },
      calculateMaturityDate(days) {
        const currentDate = new Date(); 
        currentDate.setDate(currentDate.getDate() + days); 

        return currentDate.toLocaleDateString('en-GB', {
          day: '2-digit', month: 'short', year: 'numeric'
        }).toUpperCase();
      },

      goHistory() {
        this.$router.push("/web/arbitrage/packageHistory");
      },
      // open() {
      //   this.$refs.enterPin.open();
      // },

      // selectPackage(p_type){
      //   this.selectedPackage = p_type;
      //   this.open();
      // },

      submitForm(pin) {
          let formData = new FormData();
          var self = this;

          // const selectedAmount = parseFloat(document.getElementById('amount_' + this.selectedPackage.id).value);
          // const minAmount = parseFloat(this.selectedPackage.min_etnex);
          formData.append("pay_type", this.pay_type);
          formData.append("package_type_id", this.p_id);
          formData.append("amount", this.enteredAmount);
          formData.append("sec_password", pin);     
          // alert(minAmount);
          
          // if (selectedAmount < minAmount) {
          //   this.$refs.msg.makeToast("danger", self.$t("min_etnex_error"));
          //   return; 
          // } else if(!selectedAmount){
          //   this.$refs.msg.makeToast("danger", self.$t("amount_null"));
          //   return;
          // }


          var result = upgradeStaking(formData);
          self.isLoading = true;

          result
            .then(function (value) {
                console.log(value.data);
                if (value.data.code == 0) {
                  self.successMessage = "success";
                  self.SuccessModal = true;
                } else {
                    self.$refs.msg.makeToast("danger", self.$t(value.data.message));
                }
                self.sending = false;
                self.isLoading = false;
            })
            .catch(function (error) {
                self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
                self.sending = false;
                self.isLoading = false;
            });
      },

      loadPackage(){
        var result = getPackageType();
        var self = this;
        result
          .then(function (value) {
            self.packagetype = value.data.data.package_type;
            // console.log(self.packagetype);
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },
      loadItems() {
          var result = getStakingRecord(1);
          var self = this;
          result
              .then(function(value) {
                  self.packageRecord = self.packageRecord.concat(value.data.data.user_package.data);
                  console.log(self.packageRecord);
                  
                  let alertShown = false;
                  
                  self.packageRecord.forEach(function(record) {
                      if (!alertShown) { 
                          var daysLeft = record.package.day - record.day;
                          if (daysLeft < 4) {
                            self.showRemindPopop = !self.showRemindPopop;
                              alertShown = true; 
                          }
                      }
                  });
                  
              })
              .catch(function(error) {
                  self.isLoading = false;
                  self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
              });
      },
      cryptoPrice() {
      var self = this;
      self.dataList = [];
      var result = getBitcoinPrice();
      
      result
        .then(function (value) {
          self.binance_btcprice = value.data.data.btc.binance.price;
          self.bybit_btcprice = value.data.data.btc.bybit.price;
          self.kucoin_btcprice = value.data.data.btc.kucoin.price;
          self.binance_ethprice = value.data.data.eth.binance.price;
          self.bybit_ethprice = value.data.data.eth.bybit.price;
          self.kucoin_ethprice = value.data.data.eth.kucoin.price;
          self.binance_adaprice = value.data.data.ada.binance.price;
          self.bybit_adaprice = value.data.data.ada.bybit.price;
          self.kucoin_adaprice = value.data.data.ada.kucoin.price;
          self.binance_xrpprice = value.data.data.xrp.binance.price;
          self.bybit_xrpprice = value.data.data.xrp.bybit.price;
          self.kucoin_xrpprice = value.data.data.xrp.kucoin.price;
          self.binance_solprice = value.data.data.sol.binance.price;
          self.bybit_solprice = value.data.data.sol.bybit.price;
          self.kucoin_solprice = value.data.data.sol.kucoin.price;  
        })
      
        .catch(function (error) {
          console.log(error);
        });

        var result_lookup = getDownloadLink();
        result_lookup
        .then(function (value) {
          self.market_price = value.data.data.system.etnex_price;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
      },
    },
    created() {
    this.cryptoPrice();
    },
    beforeDestroy() {
      if (this.priceUpdater) {
        clearInterval(this.priceUpdater);
      }
    },
  };
  </script>
  
<style scoped>
    .pb-10{
        padding-bottom: 10px;
    }

    hr {
        border: none;
        height: 1px;
        background-color: #393737;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .stat-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

  /* text css */
  .stat-title{
    color: var(--Secondary, #7E8088);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .stat-value{
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

  .text_16{
    margin-bottom: -1px;
    font-size: 16px;
    line-height: 18px;
  }

  .weight_600{
    font-weight: 600;
  }
  .text_h2_etnex{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  /* text css end */
  
  .etnex-package-container {
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    background: linear-gradient(90deg, #152331 0%, #000000 100%);

  }
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
   /* .back-arrow-icon, .info-icon {
    cursor: pointer;
  } */

  .info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }
  
  .package-content h2 {
    margin-bottom: 16px;
  }
  
  .purchase-details {
    border-radius: 8px;
  }
  
  .make-purchase-button {
    width: 90%;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  
  .purchase-statement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: white;
    font-family: 'Poppins', sans-serif;
  }

  .price-list {
    display: flex;
    flex-direction: column;
}

.head, .exchange {
    display: flex;
    justify-content: space-between;
    align-items: left;
    width: 100%;
}

.crypto {
    flex: 1; /* Assign flex grow to crypto blocks to distribute space evenly */
    justify-content: center; /* Center price horizontally in its container */
    margin: 0 10px; /* Add margin if needed for spacing */
}

.card {

  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
  max-width: 100%;
  margin: auto;
  color: white;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  border: 1px solid var(--Neon-Blue, #0BDDCC);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.text-container {
  flex-grow: 1;
}

.currency-name {
font-size: 20px;
font-weight: 600;
line-height: 21px;
}

.currency-price{
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
  </style>
  